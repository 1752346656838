<template>
    <div>
      <v-overlay :value="loading" :opacity="0.35" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      <v-row>
        <v-col cols="3">
          <v-text-field v-model="senderEmail" outlined placeholder="Enter sender email" hide-details/>
        </v-col>
        <v-col cols="3">
          <v-text-field v-model="subject" outlined placeholder="Enter subject" hide-details/>
        </v-col>
        <v-col cols="3">
          <v-select :items="recipiantList" v-model="recipiants"
                placeholder="Recipiants" outlined hide-details hide-no-data></v-select>
        </v-col>
        <v-col cols="3">
          <v-select :items="sports" v-model="sport"
                placeholder="Sport" outlined hide-details hide-no-data></v-select>
        </v-col>
        <v-col cols="12">
          <vue-editor v-model="emailContent" :options="editorOptions"></vue-editor>
        </v-col>
        <v-col cols="10"/>
        <v-col cols="2">
          <v-btn @click="sendEmailPressed" primary color="primary" :disabled="!(sendEmail && subject && emailContent && recipiants && sport)">Send Email</v-btn>
        </v-col>
      </v-row>
    </div>
  </template>
  
  <script>
  import { VueEditor  } from 'vue2-quill-editor';
  import { mapActions } from 'vuex'
  
  export default {
    components: { VueEditor  },
    data() {
      return {
        emailContent: '',
        senderEmail: '',
        subject: '',
        editorOptions: {
          theme: 'snow',
        },
        loading: false,
        sport: '',
        sports: ['HURLING', 'FOOTBALL', 'RUGBY', 'SOCCER'],
        recipiants: '',
        recipiantList: ['All Active', 'All emails (USE WITH CARE - IGNORES SPORT)', 'Missed last week']
      };
    },
    methods: {
      ...mapActions(['sendEmail']),
      async sendEmailPressed() {
        this.loading = true;
        const payload = {
          sender: this.senderEmail+'@knowthegame.ie',
          content: this.emailContent,
          subject: this.subject
        };
        let resp = await this.sendEmail(payload);
        this.loading = false;
        console.log(resp.data);
      },
    },
  };
  </script>