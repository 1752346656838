import Config from '@/api/config.js'

const state = {
    
};

const getters = {
    
}

const mutations = {
    
};

const actions = {
  async sendEmail({ commit }, emailData) {
    try {
      const response = await Config.sendEmail(emailData);
      return true;
      // Handle response or commit mutations if needed
    } catch (error) {
      console.error('Error sending mail:', error);
    }
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
