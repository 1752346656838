import Config from '@/api/config.js'

const state = {
    winners: [],
    winnersSport: null,
    teamWinners: [],
    teamWinningData: [],
    overallWinnerYearFromFilter: null,
    overallWinnerYearToFilter: null,
    overallCompetitionFilter: 'All Ireland',
    overallTotalsTeamSelections: [],
    overallTotalsYearFromFilter: null,
    overallTotalsYearToFilter: null,
};

const getters = {
    getWinners: state => { return state.winners },
    getTeamWinners: state => { return state.teamWinners },
    getCompetitionWinnerTotals: state => {
        const counts = {

        };

        state.winners.forEach(result => {
            if (result.runnerUp) return;
            if (state.overallWinnerYearFromFilter && state.overallWinnerYearFromFilter != 'All') {
                if (result.year < state.overallWinnerYearFromFilter) return;
            }
            if (state.overallWinnerYearToFilter && state.overallWinnerYearToFilter != 'All') {
                if (result.year > state.overallWinnerYearToFilter) return;
            }
            if (state.overallCompetitionFilter && state.overallCompetitionFilter != 'All') {
                if (result.tournament != state.overallCompetitionFilter) return;
            }
            if (result.team) {
                counts[result.team.id] = (counts[result.team.id] || 0) + 1;
            } else {
                counts['noWinner'] = (counts['noWinner'] || 0) + 1;
            }

        });

        const entries = Object.entries(counts);

        const sortedEntries = entries.sort((a, b) => b[1] - a[1]);

        return Object.fromEntries(sortedEntries);
    },
    getWinnersNames: (state, getters) => {
        return state.winners.reduce((acc, item) => {
            if (item.team) {
                acc[item.team.id] = item.team.name;
            } else {
                acc["noWinner"] = "No winner";
            }
            return acc;
        }, {});
    },
    getWinnersCodes: (state, getters) => {
        return state.winners.reduce((acc, item) => {
            if (item.team) {
                acc[item.team.id] = item.team.code;
            }
            return acc;
        }, {});
    },
    getWinnersColours: (state, getters) => {
        return state.winners.reduce((acc, item) => {
            if (item.team) {
                acc[item.team.id] = item.team.colour == "#FFFFFF" ? "#000000" : item.team.colour;
            } else {
                acc["noWinner"] = "#808080";
            }
            return acc;
        }, {});
    },
    getWinningResults: (state) => {
        let winningResults = [];
        winningResults.push({ "team": { "name": "N/A", "id": -1 }, "provincialWins": 0, "provincialRunnerUp": 0, "AllIrelandWins": 0, "AllIrelandRunnerUp": 0 });
        let removeNoWinner = true;
        for (let i = 0; i < state.winners.length; i++) {
            let winner = state.winners[i];
            if (state.overallTotalsYearFromFilter && state.overallTotalsYearFromFilter != 'All') {
                if (winner.year < state.overallTotalsYearFromFilter) continue;
            }
            if (state.overallTotalsYearToFilter && state.overallTotalsYearToFilter != 'All') {
                if (winner.year > state.overallTotalsYearToFilter) continue;
            }
            if (state.overallTotalsTeamSelections.length > 0) {
                let breakLoop = true;
                for (let j = 0; j < state.overallTotalsTeamSelections.length; j++) {
                    if (winner.team && state.overallTotalsTeamSelections[j].id === winner.team.id) {
                        breakLoop = false;
                    }
                }
                if (breakLoop) continue;
            }
            if (winner.team) {
                let winningTeamRow = winningResults.find(item => item.team.id === winner.team.id);
                if (!winningTeamRow) {
                    winningTeamRow = { "team": winner.team, "provincialWins": 0, "provincialRunnerUp": 0, "AllIrelandWins": 0, "AllIrelandRunnerUp": 0, "tier25Wins": 0, "tier25RunnerUp": 0 };
                    winningResults.push(winningTeamRow);
                }
                if (winner.tournament == "All Ireland") {
                    if (winner.runnerUp) {
                        winningTeamRow.AllIrelandRunnerUp++;
                    } else {
                        winningTeamRow.AllIrelandWins++;
                    }
                } else if (winner.tournament == "Munster" || winner.tournament == "Leinster" || winner.tournament == "Ulster" || winner.tournament == "Connacht") {
                    if (winner.runnerUp) {
                        winningTeamRow.provincialRunnerUp++;
                    } else {
                        winningTeamRow.provincialWins++;
                    }
                } else {
                    if (winner.runnerUp) {
                        winningTeamRow.tier25RunnerUp++;
                    } else {
                        winningTeamRow.tier25Wins++;
                    }
                }
            } else {
                removeNoWinner = false;
                let noWinner = winningResults.find(item => item.team.id === -1);
                if (winner.tournament == "All Ireland") {
                    if (winner.runnerUp) {
                        noWinner.AllIrelandRunnerUp++;
                    } else {
                        noWinner.AllIrelandWins++;
                    }
                } else if (winner.tournament == "Munster" || winner.tournament == "Leinster" || winner.tournament == "Ulster" || winner.tournament == "Connacht") {
                    if (winner.runnerUp) {
                        noWinner.provincialRunnerUp++;
                    } else {
                        noWinner.provincialWins++;
                    }
                } else {
                    if (winner.runnerUp) {
                        noWinner.tier25RunnerUp++;
                    } else {
                        noWinner.tier25Wins++;
                    }
                }
            }
        }
        if (removeNoWinner) {
            return winningResults.filter(item => item.team.id != -1)
        } else {
            return winningResults;
        }
    },
    getWinningTeamsHurling: (state) => {
        return state.winners.map(win => win.team)
    },
    getWinningResultsLineData: (state) => {
        let winningResults = [];
        let years = new Set();
        let removeNoWinner = true;
        for (let i = 0; i < state.winners.length; i++) {
            let winner = state.winners[i];
            if (state.overallTotalsTeamSelections.length > 0) {
                for (let j = 0; j < state.overallTotalsTeamSelections.length; j++) {
                    if (winner.team && state.overallTotalsTeamSelections[j].id === winner.team.id) {
                        let winningTeamRow = winningResults.find(item => item.id === winner.team.id);
                        if (!winningTeamRow) {
                            winningTeamRow = { "name": winner.team.name, "id": winner.team.id, "data": [0], "colour": winner.team.colour == "#FFFFFF" ? "#000000" : winner.team.colour };
                            winningResults.push(winningTeamRow);
                        }
                        if (winner.tournament == "All Ireland") {
                            if (!winner.runnerUp) {
                                winningTeamRow.data.push(winningTeamRow.data[winningTeamRow.data.length - 1] + 1);
                                years.add(winner.year);
                            }
                        }
                    } else if (winner.team) {
                        if (winner.tournament == "All Ireland") {
                            if (!winner.runnerUp) {
                                let losingTeamRow = winningResults.find(item => item.id === state.overallTotalsTeamSelections[j].id);
                                if (!losingTeamRow) {
                                    losingTeamRow = {
                                        "name": state.overallTotalsTeamSelections[j].name, "id": state.overallTotalsTeamSelections[j].id,
                                        "data": [0], "colour": state.overallTotalsTeamSelections[j].colour
                                    };
                                    winningResults.push(losingTeamRow);
                                }
                                losingTeamRow.data.push(losingTeamRow.data[losingTeamRow.data.length - 1]);
                                years.add(winner.year);
                            }
                        }
                    }
                }
            }
        }
        let yearsArray = [...years];
        if (state.overallTotalsYearFromFilter && state.overallTotalsYearFromFilter != 'All') {
            for (let row of winningResults) {
                row.data = row.data.slice(yearsArray.indexOf(state.overallTotalsYearFromFilter))
            }
            yearsArray = yearsArray.filter(it => it >= state.overallTotalsYearFromFilter)
        }
        if (state.overallTotalsYearToFilter && state.overallTotalsYearToFilter != 'All') {
            for (let row of winningResults) {
                row.data = row.data.slice(0, yearsArray.indexOf(state.overallTotalsYearToFilter))
            }
            yearsArray = yearsArray.filter(it => it <= state.overallTotalsYearToFilter)
        }
        let lineData = {};
        lineData.data = winningResults;
        lineData.years = yearsArray;
        return lineData;
    }
}

const mutations = {
    setWinners(state, winners) {
        state.winners = winners;
    },
    setWinnersSport(state, sport) {
        state.winnersSport = sport;
    },
    setTeamWinners(state, winners) {
        state.teamWinners = winners;
    },
    setTeamWinningData(state, winningData){
        state.teamWinningData = winningData
    },
    setTeamWinnersId(state, id) {
        state.winnersId = id;
    },
    setOverallWinnerYearFromFilter(state, value) {
        state.overallWinnerYearFromFilter = value;
    },
    setOverallWinnerYearToFilter(state, value) {
        state.overallWinnerYearToFilter = value;
    },
    setOverallCompetitionFilter(state, value) {
        state.overallCompetitionFilter = value;
    },
    setOverallTotalsTeamSelections(state, value) {
        state.overallTotalsTeamSelections = value;
    },
    setOverallTotalsYearFromFilter(state, value) {
        state.overallTotalsYearFromFilter = value;
    },
    setOverallTotalsYearToFilter(state, value) {
        state.overallTotalsYearToFilter = value;
    },
};

const actions = {
    async fetchWinners({ commit }, sport) {
        try {
            if(state.winnersSport == sport && state.winners){
                return;
            }
            const response = await Config.findWinnersByCompetition(sport); // Make API call
            let results = response.data.map(encoded => {
                // Step 1: Decode the Base64 string
                const jsonString = atob(encoded);
        
                // Step 2: Parse the JSON string into an object
                return JSON.parse(jsonString);
              });
            commit('setWinners', results);
            commit('setWinnersSport', sport);
            // Handle response or commit mutations if needed
        } catch (error) {
            console.error('Error fetching winners:', error);
        }
    },
    async fetchWinnersForTeam({ commit }, teamId) {
        try {
            const response = await Config.findWinnersByTeam(teamId);
            let results = response.data.map(encoded => {
                // Step 1: Decode the Base64 string
                const jsonString = atob(encoded);
        
                // Step 2: Parse the JSON string into an object
                return JSON.parse(jsonString);
              });
            commit('setTeamWinners', results);
            // Handle response or commit mutations if needed
        } catch (error) {
            console.error('Error fetching winners:', error);
        }
    },
    async fetchWinnersForSport({ commit }, sport) {
        try {
            const response = await Config.findWinnersBySport(sport);
            commit('setTeamWinningData', response.data);
            // Handle response or commit mutations if needed
        } catch (error) {
            console.error('Error fetching winners:', error);
        }
    },
    updateOverallWinnerYearFromFilter({ commit }, value) {
        commit('setOverallWinnerYearFromFilter', value)
    },
    updateOverallWinnerYearToFilter({ commit }, value) {
        commit('setOverallWinnerYearToFilter', value)
    },
    updateOverallCompetitionFilter({ commit }, value) {
        commit('setOverallCompetitionFilter', value)
    },
    updateOverallTeamSelections({ commit }, value) {
        commit('setOverallTotalsTeamSelections', value)
    },
    updateOverallTotalsYearFromFilter({ commit }, value) {
        commit('setOverallTotalsYearFromFilter', value)
    },
    updateOverallTotalsYearToFilter({ commit }, value) {
        commit('setOverallTotalsYearToFilter', value)
    },
    resetWinnerFilterOptions({ commit }) {
        commit('setOverallTotalsYearToFilter', null);
        commit('setOverallTotalsYearFromFilter', null);
        commit('setOverallTotalsTeamSelections', null);
        commit('setOverallCompetitionFilter', null);
        commit('setOverallWinnerYearToFilter', null);
        commit('setOverallWinnerYearFromFilter', null);
    }
};

export default {
    state,
    mutations,
    actions,
    getters
};
